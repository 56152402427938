.container {
  padding: 1% 0% 0% 0%;
  border-radius: 8px;
  margin: 2% 4% 0% 4%;
  position: relative;
  z-index: 10000;
}

.flexContainer {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 1rem 6%;
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  margin-top: 0.2rem;
}

.menuIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  gap: 3%;
  padding-bottom: 2%;
}

.collapseContainer {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  z-index: 20;
  transition: height 0.5s ease-out, padding 0.5s ease-out;
  overflow: hidden;
}

.collapseContent {
  font-size: 1em;
  color: var(--fiva-color);
  padding: 1% 7% 0px 7%;
  background-color: var(--fiva-secondary-color, #ddfffd);
  border-radius: 20px;
}

.subMenu {
  padding-left: 20px;
}

.underline {
  text-decoration: none;
  color: var(--fiva-color);
}

.underline:hover {
  text-decoration: underline;
  text-decoration-color: aqua;
  text-decoration-thickness: 3px;
  cursor: pointer;
}
