.container {
  padding: 1.5rem 4.5rem;
  position: relative;
  z-index: 10;
  /* Ensure the navbar is above other elements */
  /* Ensure the background color */
}

.flexContainer {
  display: flex;
  align-items: center;
  gap: 10%;
}

.links {
  display: flex;
  align-items: center;
  color: var(--fiva-color, #401c72);
  width: 52%;
  padding: 0.8rem 2rem 0.8rem 2rem;
  background-color: var(--fiva-secondary-color, #ddfffd);
  justify-content: space-between;
  border-radius: 50px;
}

.underline {
  font-stretch: condensed;
  cursor: pointer;
  display: inline-block;
  /* Ensure it only takes up as much width as needed */
  position: relative;
  padding: 0.1em 0;
  margin: 0% !important;
  width: auto;
  /* Ensure it only takes up as much width as needed */
}

.selectedUnderline {
  font-stretch: condensed;
  cursor: pointer;
  display: inline-block;
  /* Change to inline-block */
  position: relative;
  padding: 0.1em 0;
  margin: 0% !important;
}

.underline,
.selectedUnderline,
.underlineCollapse {
  font-size: 1.1rem;
}

.selectedUnderline::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0.1em;
  background-color: aqua;
  opacity: 1;
  transform: scale(1);
  transform-origin: center;
  transition: opacity 300ms, transform 300ms;
}

.underline::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0.1em;
  background-color: aqua;
  opacity: 0;
  transition: opacity 300ms, transform 300ms;
}

.underline:hover::after,
.underline:focus::after {
  opacity: 1;
  transform: translate3d(0, 0.2em, 0);
}

.underline:after {
  opacity: 1s ease;
  transform: scale(0);
  transform-origin: center;
}

.underline:focus::after,
.underline:hover::after {
  transform: scale(1);
}

.logo {
  display: flex;
  justify-content: flex-start;
  padding-left: 4%;
  width: 15%;
  cursor: pointer;
}

.compareButton {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.compareBtn {
  width: 120px;
  height: 55%;
  background-color: aqua;
  color: var(--fiva-color);
  font-weight: bold;
}

.collapseContainer {
  position: absolute;
  top: 100%;
  left: 16.5%;
  right: 0;
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0rem 4rem;
  width: 65%;
  background-color: none;
}

.underlineCollapse:hover {
  text-decoration: underline;
  text-decoration-color: aqua;
  text-decoration-thickness: 2px;
}

@media screen and (max-width: 1785px) {
  .title {
    font-size: 3.5em;
    font-weight: bold;
    padding: 0 2%;
  }

  .flexContainer {
    display: flex;
    align-items: center;
    gap: 8%;
  }

  .underline {
    font-stretch: condensed;
    cursor: pointer;
    font-weight: 400;
    display: block;
    position: relative;
    padding: 0.1em 0;
    margin: 0% !important;
  }
}

@media screen and (max-width: 1599px) {

  .underline,
  .selectedUnderli,
  .underlineCollapsene {
    font-size: 1rem;
  }
}

@media screen and (max-width: 1400px) {
  .container {
    padding: 1.5rem 1rem;
  }

  .flexContainer {
    display: flex;
    align-items: center;
    gap: 6%;
  }

  .underline {
    font-stretch: condensed;
    cursor: pointer;
    font-weight: 400;
    display: block;
    position: relative;
    padding: 0.1em 0;
    margin: 0% !important;
  }
}

@media screen and (max-width: 1399px) {

  .underline,
  .selectedUnderline,
  .underlineCollapse {
    font-size: 0.9rem;
  }
}

@media screen and (max-width: 1200px) {

  .underline,
  .selectedUnderline,
  .underlineCollapse {
    font-size: 0.8rem;
  }
}

@media screen and (max-width: 1080px) {
  .container {
    padding: 1rem;
  }
}

@media screen and (max-width: 550px) {
  .flexContainer {
    flex-direction: column;
    gap: 1rem;
  }

  .links {
    width: 100%;
    padding: 0.5rem 1rem;
  }

  .logo {
    width: 100%;
    justify-content: center;
  }
}

@media screen and (max-width: 350px) {

  .underline,
  .selectedUnderline,
  .underlineCollapse {
    font-size: 0.7rem;
  }
}