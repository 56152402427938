.buttonClass {
  padding: 1.4rem 1.5rem !important;
  background-color: var(--fiva-color) !important;
  color: white;
  border-radius: 50px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.1rem !important;
}
.lightButtonClass {
  padding: 1.4rem 1.5rem !important;
  background-color: var(--Aguamarina-Fiva, #00f5ea);
  color: var(--fiva-color);
  border-radius: 50px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.1rem !important;
}

@media screen and (max-width: 1400px) {
  .buttonClass {
    padding: 1.4rem 1.5rem !important;
    background-color: var(--fiva-color) !important;
    color: white;
    border-radius: 50px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem !important;
  }
  .lightButtonClass {
    padding: 1.4rem 1.5rem !important;
    background-color: var(--Aguamarina-Fiva, #00f5ea);
    color: var(--fiva-color);
    border-radius: 50px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem !important;
  }
}
