.skeletonBone {
  position: relative;
  overflow: hidden;
  margin: 16px 0;
  border-radius: 50px;
  height: 25vh;
}

.animationBar {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  background: linear-gradient(
    to right,
    transparent,
    rgba(255, 255, 255, 0.5),
    transparent
  );
  transition: left 0.1s ease-out;
}

.customSkeleton {
  margin: 3% 5% 5%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

@media (max-width: 768px) {
  .customSkeleton {
    margin: 3% 2% 5%;
  }
  .skeletonBone {
    border-radius: 20px;
  }
}
