.small {
  display: none;
}

.big {
  display: block;
}

@media screen and (max-width: 1000px) {
  .small {
    display: block;
  }

  .big {
    display: none;
  }
}
