.linkContainer {
  margin-bottom: 3%;
}

.linkContainer a {
  text-decoration: none !important;
}

.linkContainer a:hover {
  text-decoration: underline !important;
  text-decoration-color: aqua !important;
  text-decoration-thickness: 2px !important;
  cursor: pointer;
}

.policy {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 1%;
  font-size: 0.9em;
  margin-bottom: 3%;
  padding-top: 3%;
}

.policy p:hover {
  text-decoration: underline;
  text-decoration-color: aqua;
  text-decoration-thickness: 2px;
  cursor: pointer;
}

.container p{
  margin-bottom: 0.5rem;
}

.container h4{
  margin-bottom: 1rem;
}